import React from 'react';
import SEO from '../components/seo';
import HeroSection from '../components/HeroSection';
import {
  Heading1,
  Heading2,
  Heading3,
  Paragraph,
} from '../components/Typography';
import Section from '../components/Section';
import Card from '../components/Card';
import Link from '../components/Link';
import styled from 'styled-components';
import OnlinePayment from '../images/online-payment.svg';
import PhoneIcon from '../images/phone-icon.svg';
import Button from '../components/Button';

const PayNow = () => {
  return (
    <>
      <SEO
        title='Pay Now'
        description={`
                Jacobs Enforcement strives to make repaying your debt as straightforward as possible.
                `}
      />
      <HeroSection paddingTop='8px'>
        <Heading1 marginTop='120px'>Pay Now</Heading1>
      </HeroSection>
      <Section backgroundColor='grey' paddingTop='74px' paddingBottom='80px'>
        <Wrapper>
          <Card padding='24px' flexBasis='100%' marginTop='16px'>
            <img src={OnlinePayment} alt='' />
            <Heading3>Pay Online</Heading3>
            <Paragraph textAlign='center'>
              You will need your Jacobs reference and your Client Reference to
              make a payment.
            </Paragraph>
            <Button as='a' href='/web-services/pay-now'>
              Access Pay Now
            </Button>
          </Card>
          <Card padding='24px' flexBasis='100%' marginTop='16px'>
            <img src={PhoneIcon} alt='' />
            <Heading3 textAlign='center' marginBottom='4px'>
              Automated Payment Line
            </Heading3>
            <Link href='tel:0330 008 0430'>
              <Heading2 textAlign='center'>0330 008 0430</Heading2>
            </Link>
            <Heading3 textAlign='center' marginBottom='4px'>
              {' '}
              Welsh Language Automated Payment Line
            </Heading3>
            <Link href='tel:0330 174 4504'>
              <Heading2 textAlign='center'>0330 174 4504</Heading2>
            </Link>
          </Card>
        </Wrapper>
        <Notification>
          View our <Link href='/ways-to-pay'> ways to pay </Link> page for more
          payment options.
        </Notification>
      </Section>
    </>
  );
};

export default PayNow;

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-content: center;
  max-width: 840px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 600px) {
    display: block;
    row-gap: 24px;
  }
`;

const Notification = styled.div`
  justify-content: center;
  text-align: center;
  background: red;
  border-radius: 2px;
  max-width: 480px;
  margin: 36px auto;
  font-size: 16px;
  border: 1px solid #b7bfd7;
  background: #eef1f8;
  padding: 8px;
`;
